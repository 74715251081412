import * as React from "react";
import { Button, Table } from "semantic-ui-react";
import { Candidate } from "src/App/People/src/candidates";
import { CandidateState, dateSortDesc } from "@momenta/common";

import { useDispatch } from "react-redux";

import { loadCurrentUser } from "../currentUser/actions";

import { WithdrawModal } from "./WithdrawModal";

import { withdrawCandidate } from "./actions";

export interface ApplicationsTableProps {
    applications: Candidate[];
}

export const ApplicationsTable: React.FC<ApplicationsTableProps> = ({ applications }) => {
    const [withdrawModalOpen, setWithdrawModalOpen] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [candidateToWithdraw, setCandidateToWithdraw] = React.useState<Candidate>();
    const dispatch = useDispatch();

    const getWithdraw = (candidate: Candidate) => {
        return () => {
            setWithdrawModalOpen(true);
            setCandidateToWithdraw(candidate);
        };
    };

    const withdrawApplication = async (reason: number) => {
        setWithdrawModalOpen(false);
        setSaving(true);
        await dispatch(withdrawCandidate(candidateToWithdraw.id, { withdrawingCandidatesConfigId: reason, emailToBeSent: false }));
        await dispatch(loadCurrentUser());
        setSaving(false);
    };

    const getCandidateState = (candidate: Candidate) => {

        const state = candidate.state;

        if (candidate.withdrawn) {
            return "Application Unsuccessful";
        }
        if (state >= CandidateState["On Contract"]) {
            return "Application Successful";
        }

        if (state >= CandidateState.Pipeline && state <= CandidateState["Under Offer"]) {
            return "Application In Progress";
        }

        if (state >= CandidateState["On Contract"]) {
            return "Application Successful";
        }

        return "Unknown";
    };

    const getWithdrawButton = (candidate: Candidate) => {
        const state = candidate.state;

        if ((state === CandidateState.Pipeline || state === CandidateState["Long List"]) && candidate.withdrawn === false) {
            return <Button negative content="Withdraw" onClick={getWithdraw(candidate)} ></Button>;
        }

        return <></>;
    };

    return (
        <>
            <h2>Applications</h2>
            <Table className={applications.length > 0 ? "scrollable-table" : ""}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Role</Table.HeaderCell>
                        <Table.HeaderCell>Date of Application</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>

                    {applications.length === 0 && (
                        <Table.Row>
                            <Table.Cell content={"You have not applied for any roles"} colSpan={6} textAlign="center" disabled />
                        </Table.Row>
                    )}

                    {applications.sort(dateSortDesc(c => c.created)).map((candidate, index) => (
                        <Table.Row key={index}>
                            <Table.Cell content={candidate.role?.title} />
                            <Table.Cell content={candidate.created?.format("L")} />
                            <Table.Cell content={getCandidateState(candidate)} />
                            <Table.Cell content={getWithdrawButton(candidate)} />
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <WithdrawModal
                open={withdrawModalOpen}
                saving={saving}
                modalOpen={setWithdrawModalOpen}
                withdraw={withdrawApplication as any}
            />
        </>
    );
};
